let screenWidth;
const siteBody = document.body;
const filters = document.querySelector(".filters");
const stickyTitle = document.querySelector(".sticky--title");
const headerUpper = document.querySelector(".header--upper");
const headerLogo = document.querySelector(".header--logo");

window.addEventListener("DOMContentLoaded", () => {
  screenWidth = window.innerWidth;
  cssRootVariable(siteBody, filters, "--filters-height");
  cssRootVariable(siteBody, stickyTitle, "--sticky-title-height");
  cssRootVariable(siteBody, headerUpper, "--header--upper");
  cssRootVariable(siteBody, headerLogo, "--header--logo");
  hashScroller(".scroll-to-hash");
  if (screenWidth >= 992) {
    hashScroller(".scroll-to-hash-extra", ".sticky--title");
  } else {
    hashScroller(".scroll-to-hash-extra", ".property__sticky--section.second");
  }
  hoverClassToggler(".plan__sliderwrap", ".plan__item", "plan__item--active");
  let js_show = document.querySelectorAll(".js-show");
  let js_show_slow = document.querySelectorAll(".js-show-slow");
  js_show.forEach((js_show_item) => {
    js_show_item.style.opacity = "1";
  });
  setTimeout(function () {
    js_show_slow.forEach((js_show_slow_item) => {
      js_show_slow_item.style.opacity = "1";
    });
  }, 2000);
});

// * CHECK IF SCREEN RESIZING HAS ENDED
var rtime;
var timeout = false;
var delta = 200;
window.addEventListener("resize", () => {
  cssRootVariable(siteBody, filters, "--filters-height");
  cssRootVariable(siteBody, stickyTitle, "--sticky-title-height");
  rtime = new Date();
  if (timeout === false) {
    timeout = true;
    setTimeout(resizeend, delta);
  }
});

let resizeend = () => {
  if (new Date() - rtime < delta) {
    setTimeout(resizeend, delta);
  } else {
    timeout = false;
    // SCREEN RESIZING ENDS HERE - APPLY CHANGES BELOW
    screenWidth = window.innerWidth;
  }
};

// Functions
let cssRootVariable = (scope, target, propertyName) => {
  if (target) {
    var value;
    if (typeof target === "number") {
      value = target;
    } else {
      value = target.offsetHeight;
    }
    scope.style.setProperty(propertyName, `${value}px`);
  }
};

// toggle class with reset to default state
let hoverClassToggler = (parent, target, toggleClass) => {
  var defaultSelectedElement = 1;
  var wrapEl = document.querySelector(parent);
  var el = document.querySelectorAll(target);

  if (wrapEl) {
    for (var i = 0; i < el.length; i++) {
      el[i].onmouseenter = function () {
        for (var j = 0; j < el.length; j++) {
          el[j].classList.remove(toggleClass);
        }
        this.classList.add(toggleClass);
      };
      el[i].onmouseleave = function () {
        this.classList.remove(toggleClass);
      };
    }
    wrapEl.onmouseleave = function () {
      el[defaultSelectedElement - 1].classList.add(toggleClass);
    };
  }
};

let hashScroller = (cl, xh) => {
  let scrollers = document.querySelectorAll(cl);
  scrollers.forEach((scroller) => {
    scroller.addEventListener("click", function (e) {
      e.preventDefault();
      headerHeight = document.querySelector("header").offsetHeight;
      $target = document.querySelector(this.href.substring(this.href.lastIndexOf("/") + 1));
      if (xh) {
        if (screenWidth >= 992) {
          extraHeight = document.querySelector(xh).offsetHeight;
          var hh =
            $target.getBoundingClientRect().top + window.scrollY - headerHeight - extraHeight - 55;
        } else {
          extraHeight = document.querySelector(xh).offsetHeight;
          var hh =
            $target.getBoundingClientRect().top + window.scrollY - headerHeight - extraHeight;
        }
      } else {
        var hh = $target.getBoundingClientRect().top + window.scrollY - headerHeight;
      }

      window.scrollTo({
        top: hh,
        behavior: "smooth",
      });
    });
  });
};

// window.addEventListener("DOMContentLoaded", () => {
//   document.addEventListener("keydown", function (e) {
//     e = e || window.event;
//     e.keyCode;
//     if (e.keyCode == 27) {
//       enquireForm.forEach((formEl) => {
//         formEl.classList.remove("active");
//       });
//       pageOverlay.classList.remove("active");
//     }
//   });
// });
window.addEventListener("DOMContentLoaded", () => {
  document.addEventListener("keydown", function (e) {
    e = e || window.event;
    e.keyCode;
    if (e.keyCode === 27) {
      $(".searchModal__close").click();
    }
  });
});

//remove uppercase accents
(function (jQuery) {
  jQuery.extend(jQuery.expr[":"], {
    uppercase: function (elem) {
      var attr = jQuery(elem).css("text-transform");
      return typeof attr !== "undefined" && attr === "uppercase";
    },
    smallcaps: function (elem) {
      var attr = jQuery(elem).css("font-variant");
      return typeof attr !== "undefined" && attr === "small-caps";
    },
  });

  jQuery.extend({
    removeAcc: function (elem) {
      // Replace uppercase accented greek characters
      function removeAccEL(text) {
        return typeof text !== "string"
          ? // handle cases that text is not a string
            text
          : // global replace of uppercase accented characters
            text
              .replace(/\u0386/g, "\u0391") // 'Ά':'Α'
              .replace(/\u0388/g, "\u0395") // 'Έ':'Ε'
              .replace(/\u0389/g, "\u0397") // 'Ή':'Η'
              .replace(/\u038A/g, "\u0399") // 'Ί':'Ι'
              .replace(/\u038C/g, "\u039F") // 'Ό':'Ο'
              .replace(/\u038E/g, "\u03A5") // 'Ύ':'Υ'
              .replace(/\u038F/g, "\u03A9") // 'Ώ':'Ω'
              .replace(/\u0390/g, "\u03CA") // 'ΐ':'ϊ'
              .replace(/\u03AC/g, "\u03B1") // 'ά':'α'
              .replace(/\u03AD/g, "\u03B5") // 'έ':'ε'
              .replace(/\u03AE/g, "\u03B7") // 'ή':'η'
              .replace(/\u03AF/g, "\u03B9") // 'ί':'ι'
              .replace(/\u03B0/g, "\u03CB") // 'ΰ':'ϋ'
              .replace(/\u03CC/g, "\u03BF") // 'ό':'ο'
              .replace(/\u03CD/g, "\u03C5") // 'ύ':'υ'
              .replace(/\u03CE/g, "\u03C9"); // 'ώ':'ω'
      }

      jQuery(elem)
        .each(function () {
          this.value = removeAccEL(this.value);
        })
        .contents()
        .filter(function () {
          return this.nodeType === 3; // Node.TEXT_NODE
        })
        .each(function () {
          this.nodeValue = removeAccEL(this.nodeValue);
        });
    },
  });

  jQuery.fn.extend({
    removeAcc: function () {
      return this.each(function () {
        jQuery.removeAcc(this);
      });
    },
  });
})(jQuery);

jQuery(document).ready(function ($) {
  $(":uppercase:not(input[type!=submit], textarea, .no-remove-accents)").removeAcc();
  $(":smallcaps:not(input[type!=submit], textarea, .no-remove-accents)").removeAcc();
  $(
    ".remove-accents, .remove-accents > *:not(input[type!=submit], textarea, .no-remove-accents)",
  ).removeAcc();
  $(document).ajaxComplete(function (event, request, settings) {
    $(":uppercase:not(input[type!=submit], textarea, .no-remove-accents)").removeAcc();
    $(":smallcaps:not(input[type!=submit], textarea, .no-remove-accents)").removeAcc();
    $(
      ".remove-accents, .remove-accents > *:not(input[type!=submit], textarea, .no-remove-accents)",
    ).removeAcc();
  });
});
