jQuery.fn.extend({
  autoHeight: function () {
    function autoHeight_(element) {
      const style = window.getComputedStyle(element);
      const paddingTop = Math.round(parseFloat(style.getPropertyValue("padding-top")));
      const paddingBottom = Math.round(parseFloat(style.getPropertyValue("padding-bottom")));
      return jQuery(element)
        .css({ height: 0, "min-height": 0, "overflow-y": "hidden" })
        .height(element.scrollHeight - paddingTop - paddingBottom);
    }
    return this.each(function () {
      autoHeight_(this).on("input", function () {
        autoHeight_(this);
      });
      jQuery(window).resize(() => {
        autoHeight_(this);
      });
    });
  },
});
