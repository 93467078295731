import { Tooltip } from "bootstrap";

const tooltipTriggerList = document.querySelectorAll(
  '[data-bs-toggle="tooltip"]',
);

const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
  const tooltip = new Tooltip(tooltipTriggerEl, {
    animation: true, // Keep Bootstrap's default animation
  });

  tooltipTriggerEl.addEventListener("shown.bs.tooltip", function () {
    const tooltipElement = tooltip._getTipElement();
    if (tooltipElement) {
      tooltipElement.style.transition = "opacity 0.3s ease";
      tooltipElement.style.opacity = "1";
    }
  });

  tooltipTriggerEl.addEventListener("hide.bs.tooltip", function () {
    const tooltipElement = tooltip._getTipElement();
    if (tooltipElement) {
      tooltipElement.style.transition = "opacity 0.3s ease";
      tooltipElement.style.opacity = "0";
    }
  });

  tooltipTriggerEl.addEventListener("inserted.bs.tooltip", function () {
    const tooltipElement = tooltip._getTipElement();
    if (tooltipElement) {
      tooltipElement.style.transition = "opacity 0.3s ease";
      tooltipElement.style.opacity = "0";
      setTimeout(() => {
        tooltipElement.style.opacity = "1";
      }, 10);
    }
  });

  return tooltip;
});
