import $ from "jquery";
import "@fancyapps/fancybox";
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";

$('[data-fancybox="gallery"]').fancybox({
  loop: false,
  clickContent: false,
  infobar: false,
  buttons: ["close"],
  protect: true,
  btnTpl: {
    close: `
      <div data-fancybox-close class="fancybox-button fancybox-button--close" title="Close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 34" fill="currentColor">
            <line x1="2.53033" y1="1.46991" x2="33.643" y2="32.5826" stroke="currentColor" stroke-width="1.5"/>
            <line x1="1.46991" y1="32.4695" x2="32.5826" y2="1.35685" stroke="currentColor" stroke-width="1.5"/>
        </svg>
      </div>
    `,
    arrowLeft: `
      <div class="nav nav--lg nav--arrow-expanded nav--reverse color--light fancybox-button fancybox-button--arrow_left" data-fancybox-prev title="Previous"></div>
    `,
    arrowRight: `
      <div class="nav nav--lg nav--arrow-expanded color--light fancybox-button fancybox-button--arrow_right" data-fancybox-next title="Next"></div>
    `,
  },
  afterShow: function (instance, current) {
    const totalSlides = instance.group.length;
    const currentIndex = current.index;

    // Disable prev button if on the first slide
    if (currentIndex === 0) {
      $(".fancybox-button--arrow_left").addClass("disabled");
    } else {
      $(".fancybox-button--arrow_left").removeClass("disabled");
    }

    // Disable next button if on the last slide
    if (currentIndex === totalSlides - 1) {
      $(".fancybox-button--arrow_right").addClass("disabled");
    } else {
      $(".fancybox-button--arrow_right").removeClass("disabled");
    }
  },
  afterClose: function (instance, current) {
    // Remove disabled class on close to reset the state
    $(".fancybox-button--arrow_left").removeClass("disabled");
    $(".fancybox-button--arrow_right").removeClass("disabled");
  },
});
