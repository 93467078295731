import App from "./src/App";
window.app = App;
import Cart from "./src/Cart";
window.cart = Cart;
//js
// LIBRARY
import "./library";
// ANIMATIONS
import "./animations";
// THEME
import "./theme";
// TOOLTIP
import "./bootstrap/tooltip";
// ALERT
import "bootstrap/js/src/alert";

// Header Sticky Style
import $ from "jquery";
let lastScrollTop = 0;
const header = $(".site-header");
const body = $("body");
$(window).scroll(function (event) {
  let st = $(this).scrollTop();

  if (st < 100) {
    header.removeClass("header-scrolled");
    return;
  }

  header.addClass("header-scrolled");
});
